<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <ds-themed-text-section *ngIf="hasSiteFooterSections" [sectionId]="'site'" [textRowSection]=section [site]="(site | async)"></ds-themed-text-section>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer py-3 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        {{ 'footer.built-with' | translate }}
        <a class="text-white" href="https://wiki.lyrasis.org/display/DSPACECRIS/">{{ 'footer.link.dspace-cris' | translate}}</a>
        {{ 'footer.extension-by' | translate }}
          <img src="assets/images/logo-4science-small.png" alt="{{ 'footer.link.4science' | translate}}" />
	 - Hosting & support
	<a href="https://scimagolab.com" target="_new">
	  <img src="assets/images/scimago-logo.png" width="120px" alt="SCImago Lab" />
        </a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0 mt-1">
        <li>
          <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white" routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->
</footer>
